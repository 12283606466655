<template>
  <div class="auth-right confirm-mail-div zoom">
    <loading :loading="loading" />

    <div class="container" style="padding-top: 187px" v-show="!loading">
      <div class="row">
        <div class="col-md-12 text-center">
          <img
            src="/img/confirm-logos.svg"
            class="img-fluid confirm-logo"
            alt=""
          />
          <h1>
           {{$t("auth.general.welcome-msg")}}
          </h1>

          <div class="row">
            <div class="col-md-2 offset-md-5 loader-div">
              <div class="loader"></div>
            </div>
          </div>

          <p href="#">
            <img src="/img/account-icons.svg" class="img-fluid mr-2" alt="" />
            {{$t("auth.bufferpage.info-p")}}  
            <a @click="formSubmit()">{{$t("buttons.clickhere")}}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmMail from "@/graphql/auth/confirmMail.gql";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    ...mapMutations({
      setEmail: "general/setEmail",
      setCompleteStep: "general/setCompleteStep",
    }),
    redirectStepOne() {
      setTimeout((x) => this.$router.push("/register-steps/step-1"), 2000);
    },
    async formSubmit() {
      try {
        this.loading = true;
        let variable = {
          code: this.$route.params.id,
        };
        let data = await this.$apollo.mutate(ConfirmMail, variable);
        let response = data("**.confirmEmail");

        this.setEmail(response.email);
        this.setCompleteStep(-1);

        this.redirectStepOne();
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.formSubmit();
  },
};
</script>
